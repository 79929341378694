@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-datepicker/dist/react-datepicker.css";
@import 'react-responsive-modal/styles.css';

@layer components {

    .btn-secondary-dark {
        @apply inline-flex items-center px-4 py-1.5 bg-secondary-dark text-white rounded-md border border-secondary-dark shadow-sm text-base;
    }

    .btn-alert {
        @apply inline-flex items-center px-4 py-1.5 bg-blue-50 text-black rounded-md border border-white shadow-sm text-base;
    }

    .badge-red {
        @apply inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-800;
    }

    .badge-green {
        @apply inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-200 text-green-800;
    }

    .badge-blue {
        @apply inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-200 text-blue-800;
    }

    .badge-gray {
        @apply inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800
    }

    .badge-large-rounded-red {
        @apply inline-flex items-center px-2 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800;
    }

    .table-responsive {
        @apply overflow-y-hidden overflow-x-auto
    }
}

.wm-600 {
    min-width: 600px;
    max-width: 600px;
}

.cursor {
    cursor: pointer;
}

.svg {
    color: "#333"
}

.hover {
    background-color: #fff;
    -moz-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.hover:hover,
.hover.active {
    transform: scale(1.05);
    background-color: #ccc;
}

.container-dentsSVG {
    position: relative;
}

.frame {
    font-size: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.frame-white>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-blue>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    background-color: blue;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-red>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    background-color: red;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-azulito>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    background-color: #84b0e2;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-rojito>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    background-color: #cd9393;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-amarillito>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    background-color: #c6b543;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-border-red>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px red;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-border-dark>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #333;
    margin-right: 5px;
    margin-left: 10px;
}

.frame-border-light>div {
    float: left;
    width: 20px;
    height: 20px;
    border: solid 2px #ccc;
    margin-right: 5px;
    margin-left: 10px;
}