.d-none{
  display: none;
}

.st0{
  /*fill:#FFFFFF;*/
  fill:rgba(255, 255, 255, .1);
}

.dents{
  width: 40px;
  padding: 0px 6px;
}

.container-dentsSVG{
  width: 100%;
}

.dentsCursorPointer{
  cursor: pointer;
}

.dentsSVG svg g path {
  cursor: pointer;
}

/*dientes sanos sección*/
g.dentsSVGSano path,
g.dentsSVGSanoHover:hover path {
   /* stroke: black;
   stroke-width: 50; */
   cursor: pointer;
}

/*dientes con obturación*/
g.dentsSVGObturacion path,
g.dentsSVGObturacionHover:hover path {
    fill:blue;
    stroke-width: 16.5;
    cursor: pointer;
}

/*dientes con caries*/
g.dentsSVGCaries path,
g.dentsSVGCariesHover:hover path {
    fill:red;
    stroke-width: 16.5;
    cursor: pointer;
}

/*dientes con caries*/
g.dentsSVGRecidiva path,
g.dentsSVGRecidivaHover:hover path {
    stroke: red;
    stroke-width: 52;
    cursor: pointer;
}

/*Resina sección*/
g.dentsSVGResina path,
g.dentsSVGResinaHover:hover path {
   stroke: black;
   stroke-width: 1.5;
   cursor: pointer;
   fill: #84b0e2;
}


/*Resina indicada sección*/
g.dentsSVGResinaIndicada path,
g.dentsSVGResinaIndicadaHover:hover path {
   stroke: black;
    stroke-width: 1.5;
   cursor: pointer;
    fill: #cd9393;
}

/*paciente clínicamente sano sección*/
g.dentsSVGClinicamenteSano path,
g.dentsSVGClinicamenteSanoHover:hover path {
   stroke: #ccc;
   stroke-width: 80;
   cursor: pointer;
}

/*paciente clínicamente sano sección*/
g.dentsSVGEdentulo path,
g.dentsSVGEdentuloHover:hover path {
   stroke: #ccc;
   stroke-width: 80;
   cursor: pointer;
}
/*Erosion Dental*/

g.dentsSVGErosion path,
g.dentsSVGErosionHover:hover path {
    fill:#c6b543;
    stroke-width: 16.5;
    cursor: pointer;
}



















.dentsSVGSecondary{
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.dentsSVGBlue{
  stroke:blue;
  stroke-width:3;
}
.dentsSVGTextBlue{
  color: blue;
  stroke:blue;
  stroke-width:1.5;
}
.dentsSVGGreen{
  stroke:green;
  stroke-width:3;
}
.dentsSVGRed{
  stroke:red;
  stroke-width:3;
}
.dentsSVGTextRed{
  stroke:red;
  color: red;
  stroke-width:1.5;
}
.dentsSVGTextRed.text{
    color: red;
}
.dentsSVGBlack{
  stroke:black;
  stroke-width:3;
}
